
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'Theme3PopupComponent',
    computed: {
        ...mapGetters({
            getCMS: 'cms/getCMS',
            splashScreen: 'cms/splashScreen',
            maintenance: 'themes/settings/maintenance',
        }),
        ...mapState({
            dialog: (state) => state.settings.displayPopup,
            loading: (state) => state.settings.changeLanguageLoading,
        }),
        isImageExist() {
          if (!this.splashScreen?.items) return false;
          return this.splashScreen.items.some(item =>
              !!this.cmsImageLink({
                  path: (item?.image_path && item?.image_path[this.language]) || '',
                  code: this.splashScreen.component_code,
              })
          );
        },

        showSplashScreen() {
            return this.splashScreen?.items && !this.loading && this.isImageExist;
        }
    },
    mounted() {},
    methods: {
        closePopup() {
            this.$store.commit('settings/setDisplayPopup', false)
            // Kevin told me to remove the 1 hour timer
            // this.$cookie.set('hidePopup', true, {
            //     path: '/',
            //     maxAge: 60 * 60, // 1 hour
            // })
        },
    },
}
